

iframe#webpack-dev-server-client-overlay{display:none!important} 

#root {
  width: 100vw;
  height: 100vh;
}



body {
  overflow-x: hidden;
  height: 100%;
  width: 100%;
margin: 0;
padding: 0;
touch-action: auto;
}




@tailwind base;
@tailwind components;
@tailwind utilities;
